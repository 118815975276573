class ResponsiveBackgroundImage {

    constructor(element) {
        this.element = element;
        this.img = element.querySelector('img');
        this.src = '';

        this.img.addEventListener('load', () => {
            this.update();
        });

        window.addEventListener('load', () => {
            this.update();
        });

        if (this.img.complete) {
            this.update();
        }
    }

    update() {
        let src = typeof this.img.currentSrc !== 'undefined' ? this.img.currentSrc : this.img.src;

        if (this.src !== src) {
            this.src = src;
            this.element.style.backgroundImage = 'url("' + this.src + '")';

        }
    }
}

$(function() {
    "use strict";

    // Responsivni zahlavi
    // -----------------------------------------------------------------------------------------------------------------
    var elements = document.querySelectorAll('.header-image');
    for (var i=0; i<elements.length; i++) {
        new ResponsiveBackgroundImage(elements[i]);
    }

    // Fancybox
    // -----------------------------------------------------------------------------------------------------------------
    $(document).ready(function() {
        $('.fancybox').fancybox();
    });

    // Události pro mobilní verzi menu
    // -----------------------------------------------------------------------------------------------------------------
    if ($(window).width() < 992) {
        $('.menu-toggle').click(function () {
            $('#navbar').toggleClass('navbar--open');
        });
    }

    $(window).resize(function () {
        if ($(window).width() < 768) {
            $('header.homepage').height($(window).width());
        }
    });

    $(window).resize();

    // Slick - mohlo by vas zajimat
    // -----------------------------------------------------------------------------------------------------------------
    $('.products-carousel').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<span class="glyphicon glyphicon-menu-left"></span>',
        nextArrow: '<span class="glyphicon glyphicon-menu-right"></span>',
        responsive: [
            {
                breakpoint: 767,
                settings: "unslick"
            }
        ]
    });

    $('.interested-carousel').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<span class="glyphicon glyphicon-menu-left"></span>',
        nextArrow: '<span class="glyphicon glyphicon-menu-right"></span>',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });

    $('.customers-carousel').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<span class="glyphicon glyphicon-menu-left"></span>',
        nextArrow: '<span class="glyphicon glyphicon-menu-right"></span>',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });

    // zmenseni loga
    // -----------------------------------------------------------------------------------------------------------------
    var smallLogoPercent = 35;
    var minHight = 40;
    var logo = $('.navbar-brand');
    logo.data('height', logo.outerHeight());

    $(window).scroll(function () {
        if (logo.outerHeight() <= minHight) {
            return;
        }

        if ($(window).width() < 768) {
            logo.outerHeight(logo.data('height'));
            return;
        }

        var top = $(window).scrollTop() / 1; // higher divisor is faster resize


        var newHeight = logo.data('height') - top;

        if (newHeight >= (logo.data('height') / 100 * smallLogoPercent)) {
            logo.outerHeight(newHeight);
        } else {
            logo.outerHeight(logo.data('height') / 100 * smallLogoPercent);
        }
    });

    $(window).resize(function () {
        // reset height and load new from CSS
        logo.attr('style', '');
        logo.data('height', logo.outerHeight());

        // fire scroll event
        $(window).scroll();
    });

    $(window).scroll();
});
